.terms {
  height: 100%;
  overflow-y: auto;
  margin: 0.5em 0;
  border: 2px solid lightgray;
  background-color: var(--colorDrawer);
  border-radius: 7px;
  padding: 2em;
  line-height: 22px;
}

.terms-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 0;
}

.terms-check h4 {
  margin: 0;
}
