.mini-changelog-list {
  font-family: "Didact Gothic";
}

.mini-changelog-list .rs-panel-heading {
  padding: 0.5em;
}

.mini-changelog-list .rs-panel-heading .rs-panel-title {
  width: 85%;
}

.mini-changelog-list .rs-tag {
  margin-right: 0.5em;
  margin-bottom: 0.25em;
}

.mini-changelog-list .current {
  background-color: var(--color3);
  color: white;
}

.mini-changelog-list .rs-panel-body {
  padding: 0.5em;
}

.mini-changelog-list .rs-panel-body h4 {
  margin: 0;
}

.mini-changelog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mini-changelog-header button {
  padding: 0 0.5em !important;
}
.mini-changelog-list .rs-panel::before {
  left: 10px !important;
  right: 10px !important;
}
