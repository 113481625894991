.locked-feature-icon {
  cursor: pointer;
  color: var(--color3);
}

.plan-upgrade {
  width: 30em;
}
.plan-upgrade-tooltip .plan-upgrade-header strong {
  color: var(--color3);
}

.plan-upgrade-tooltip .action-button {
  width: 100%;
}
