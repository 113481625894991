.popup-anim-enter {
  opacity: 0;
}
.popup-anim-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 200ms;
}
.popup-anim-exit {
  opacity: 1;
}
.popup-anim-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 200ms;
}

.popup-date {
  padding-left: 6em;
}

.popup-form-date:last-child {
  margin: 0 2.5em;
}

.popup {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  top: 0;
  background-color: rgba(77, 77, 77, 0.7);
  display: flex;
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-wrapper {
  background-color: var(--colorBG);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 90%;
}

.popup-warning {
  padding: 1em;
  max-width: 35%;
  max-height: 90%;
}

.popup-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1em;
  justify-content: space-between;
  border-bottom: 3px solid var(--colorDrawer);
}

.popup-header h2 {
  margin: 0.2em 0;
}

.popup-header img {
  width: 2em;
}

.popup-wrapper form {
  height: 1%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--color2);
  padding: 1em;
}

.popup-form-date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-form-item input,
.popup-form-item select,
.popup-form-item div {
  flex-grow: 1;
  min-width: 6em;
}
.popup-form-item input:not(:placeholder-shown):invalid,
.popup-form-item select:not(:placeholder-shown):invalid {
  border: 1px solid red;
}
.popup-form-item input:disabled,
.popup-form-item select:disabled {
  background-color: lightgray;
}

.popup-form-date input {
  width: 10.5em;
}

.popup-center {
  justify-content: center !important;
}
.popup-center h4 {
  margin: 0.5em 0;
}

.popup-stretch {
  margin-top: 1em;
  align-self: stretch;
}

.popup-stretch:disabled {
  background-color: lightgray;
  border-color: lightgray;
}

.popup-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
}

.popup-list.list-small {
  max-height: 10em;
}

.popup-list h4 {
  margin: 0.5em;
}

.popup-list.list-small li {
  text-align: right;
  width: 23em;
}
.popup-list.list-small li input {
  width: 1em;
}

.popup-header.warning-header {
  padding: 0.5em 0;
}

.popup-list ul {
  list-style-type: none;
  margin: 0.5em;
}

.popup-form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50em;
  justify-content: flex-end;
}

.popup-form-item.concat-item {
  width: 90%;
  align-self: flex-end;
}

.popup-form-item-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30em;
  justify-content: flex-end;
}

.popup-form-item-column h5,
.popup-form-item-column h4 {
  margin: 0.3em 0;
  font-size: 1em;
}

.popup-form-item-column h5 {
  margin-top: 0;
}
.popup-message-margin {
  margin: 0.5em 0;
}

.popup-form-item-column h4 {
  color: black;
}

.popup-form-item-column button {
  padding: 0.1em 1.5em;
}

.popup-message-option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
}

.popup-message-option h4,
.popup-message-margin h4 {
  color: var(--colorFont);
}

.popup-form-input-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 24em;
}

.popup-form-item-border {
  border-bottom: 3px solid var(--colorDrawer);
}

.popup-button-wrapper {
  border-top: 3px solid var(--colorDrawer);
  width: 100%;
  align-self: center;
  text-align: center;
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.popup-button-wrapper > button,
.popup-button-wrapper > a {
  flex-grow: 1;
}

.popup-button-wrapper > a button {
  width: 100%;
}

.popup-output-url {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
  background-color: darkgray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.popup-output-url textarea {
  width: 90%;
  font-family: "Didact Gothic";
  align-self: center;
  margin-bottom: 1em;
  margin-top: 0;
  padding: 0.5em;
  resize: none;
  background-color: var(--colorDrawer);
  border: none;
}
.popup-output-url h5 {
  color: white;
  margin: 0.5em;
  text-align: center;
}
.popup-textarea-input {
  width: 30em;
  resize: none;
  font-family: "Didact Gothic";
  color: var(--colorFont);
  background-color: var(--colorDrawer);
  border: 2px solid lightgray;
  border-radius: 5px;
  text-align: start;
  padding: 0.5em;
  margin: 0.5em;
}

.concatonated-param-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.concatonated-param-wrapper i {
  margin-right: 0.25em;
}

.concatonated-param-text {
  margin: 0.5em 0;
  color: var(--color2);
  cursor: pointer;
  transition: color 0.5s;
}

.concatonated-param-text:hover {
  color: var(--color1);
}

.custom-param-name {
  width: 10em !important;
}

.popup-form-items {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.tooltip-text {
  position: absolute;
  margin-left: 3em !important;
  margin-top: -15em !important;
  z-index: 10 !important;
  visibility: hidden;
  font-family: "Didact Gothic";
  max-width: 15em;
  padding: 0.5em;
  text-align: left;
  display: flex;
  color: var(--colorFont);
  flex-direction: column;
  justify-content: center;
  background-color: var(--colorBG);
  border: 1px solid rgba(53, 49, 49, 0.2);
  border-radius: 10px;
  box-shadow: 0px 1px 3px 1px rgb(53, 49, 49);
  margin-right: 1em;
  z-index: 10;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.tooltip-text div h4,
.tooltip-text div li,
.tooltip-text h5 {
  margin: 0.2em 0;
  font-size: 0.75em;
  white-space: pre-wrap;
  line-height: 125%;
}

.tooltip-text h5 {
  padding-bottom: 0.65em;
  border-bottom: 3px solid var(--colorDrawer);
}

.tooltip-text div ul {
  margin: 0;
  padding-left: 1em;
}

.tooltip-text div h4,
.tooltip-text div li {
  font-size: 0.6em;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  transition: color 0.2s ease;
  cursor: default;
}

.tooltip:hover {
  color: var(--color2);
}

.margin-right {
  margin-right: 2.75em;
}

.warning-migrate {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.popup-warning h4 strong {
  color: var(--color2);
}

.popup-form-item label {
  word-break: break-word;
  width: 25%;
  text-align: end;
}

.popup-date label {
  width: initial;
}

.popup-link {
  margin-left: 7em;
}

.popup-message {
  margin-left: 8.5em;
}

.no-pointer {
  cursor: default !important;
}

.auth-button {
  width: 100%;
  margin: 0.5em;
  font-size: 1em;
}

.tooltip-popup {
  margin-left: 3em !important;
  height: auto;
  max-width: 25em !important;
  background-color: var(--colorBG) !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-tooltip h3 {
  font-size: 1.3em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5em;
  justify-content: space-between;
  border-bottom: 3px solid var(--colorDrawer);
}

.popup-tooltip h4 {
  font-size: 1em;
  margin: 0.5em 0;
}

.popup-tooltip ul {
  padding-left: 1em;
}

.no-grow {
  flex-grow: 0 !important;
}

.outputTax {
  text-align: center;
  font-weight: bold;
}

.concatonated-param-text.margin-right.no-pointer.popup-link label {
  font-size: 1em !important;
  font-weight: bolder !important;
}

.popup-form-item input:disabled {
  background: lightgray !important;
}

.multistep:disabled {
  background-color: lightgray;
  border: none;
  cursor: default;
}

.popup-dependency {
  padding: 0.5em 1em;
  padding-top: 0;
}

.popup-dependency-tag {
  margin: 0.5em;
  background-color: var(--color2) !important;
  color: white !important;
}

.popup-dependency-tag:first-child {
  margin-left: 0;
}

.temp-popup-value .rs-picker-toggle-value {
  color: var(--color3) !important;
}

.temp-popup-value-input .rs-input {
  color: var(--color3) !important;
}

.popup .rs-picker-tag .rs-btn {
  border: none !important;
}
.popup-switch-wrapper {
  display: flex;
  flex-direction: row;
}

.switch-form-item {
  flex-direction: column;
}

.switch-form-item h5 {
  padding-left: 0.5em;
  width: 80%;
  align-self: flex-end;
}

.popup-switch {
  display: flex;
  flex-grow: 1;
  width: 10%;
}

.popup-switch .rs-btn-toolbar {
  display: flex;
  flex-grow: 1;
}

.popup-switch .rs-btn-group {
  flex-grow: 1;
  display: flex;
  border-radius: 8px;
  margin: 0.5em;
  border: 2px solid lightgray;
  flex-direction: row;
  justify-content: space-between;
}

.popup-switch button {
  width: 50%;
  background-color: var(--colorDrawer);
}

.popup-switch button:hover,
.popup-switch button:active,
.popup-switch button:focus {
  background-color: lightgray;
}
.popup-switch .selected-switch {
  background-color: var(--color2);
  color: white;
}

.popup-switch .selected-switch:hover,
.popup-switch .selected-switch:active,
.popup-switch .selected-switch:focus {
  background-color: var(--color2);
  color: white;
}

.team-permissions-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.team-permissions-item div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.team-permissions-item i {
  margin-right: 0.5em;
}

.popup-bulk-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 7px;
  padding: 0.25em 0.5em;
  margin: 0;
}

.popup-bulk-toggle .rs-btn-toggle {
  margin-left: 0.5em;
}

.popup-header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup-submit-error {
  width: 100%;
  text-align: center;
  background-color: rgba(204, 0, 0, 0.5);
  color: white;
  padding: 0.5em;
  border-radius: 5px;
  margin: 0.5em 0;
}
