.sidenav-wrapper {
  display: flex;
  flex-direction: row;
}

.sidenav {
  position: fixed;
  height: 100%;
  z-index: 100;
  width: 5em;
  background-color: var(--color1);
  box-shadow: inset -2px 0px 10px -5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.sidenav .main-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 5em;
}
.sidenav .org-img {
  margin-bottom: 6.5em;
}
.sidenav .org-img img {
  width: 3em;
  height: 3em;
  object-fit: contain;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.sidenav .tab-nav {
  margin: 0;
  margin-bottom: 4em;
}

.sidenav .upgrade-container {
  width: 100%;
  height: 3.5em;
}

.sidenav .upgrade-button {
  width: 100%;
  margin-bottom: 1em;
  color: var(--color3);
  width: 100%;
  transition: background 300ms;
  padding-bottom: 1em;
  cursor: pointer;
  border-radius: 6px 6px 0px 0px;
}
.sidenav .upgrade-button h4 {
  font-size: 0.8em;
  text-align: center;
  padding: 0 0.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.sidenav .upgrade-button:hover {
  background-color: white;
  font-weight: bold;
}
.sidenav .upgrade-button:hover h4 {
  font-weight: bold;
}

.sidenav .tab-nav .rs-nav-item-content {
  color: var(--colorBG);
  white-space: pre-wrap;
  text-align: center;
}

.sidenav .tab-nav-item .rs-nav-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.3em 1em;
}

.sidenav .tab-nav-item .icon {
  margin: 0;
  color: var(--colorBG);
  letter-spacing: initial;
  font-size: 1.5em;
}

.sidenav .rs-nav-item-content::before {
  right: 5px !important;
  width: 3px !important;
  background-color: var(--colorFont) !important;
  border-radius: 5px;
}

.sidenav .rs-nav-item-active .rs-nav-item-content,
.sidenav .rs-nav-item-active .icon,
.sidenav .rs-nav-item-active:hover .rs-nav-item-content,
.sidenav .rs-nav-item-active:active .rs-nav-item-content {
  color: var(--color1) !important;
  font-weight: bold;
}

.sidenav .rs-nav-item-content:hover,
.sidenav .rs-nav-item-content:hover .icon {
  color: var(--colorFont);
}

.sidenav .rs-nav-item-content:active,
.sidenav .rs-nav-item-content:focus {
  color: var(--colorFont) !important;
}

.sidenav .team-initials {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  background-color: var(--color3);
  border-radius: 5px;
  border: 1px solid black;
  color: white;
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
}

.sidenav .team-initials h4 {
  text-align: center;
  font-weight: bold;
}

.rs-nav-item.tab-nav-item.rs-nav-item-active {
  background-color: var(--colorDrawer);
}

.rs-nav-item.tab-nav-item {
  border-radius: 7px 0px 0px 7px;
}

.rs-nav-tabs.rs-nav-vertical
  .rs-nav-item.rs-nav-item-active
  > .rs-nav-item-content {
  border: none !important;
}

.rs-nav-tabs .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
  padding: 0.3em 1em !important;
}
.rs-nav {
  position: initial;
}

.rs-nav-tabs
  .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled)
  > .rs-nav-item-content:hover,
.rs-nav-tabs
  .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled)
  > .rs-nav-item-content:focus {
  background-color: var(--colorDrawer) !important;
}
