.radio-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.25em 0;
  padding: 0.25em;
  border: 2px solid lightgray;
  border-radius: 5px;
  background: var(--colorDrawer);
}

.radio-input input {
  width: 1em;
  margin-right: 1em;
}

.single-char-input {
  font-size: 1em;
  text-align: center !important;
  width: 2.5em !important;
  height: 2.5em !important;
}

.config-input {
  font-family: "Didact Gothic" !important;
  color: var(--colorFont);
  background-color: var(--colorDrawer) !important;
  border: 2px solid lightgray !important;
  border-radius: 5px !important;
  text-align: start;
  padding: 0.5em !important;
}
