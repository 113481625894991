.parameter-list {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  padding: 0.5em;
  background-color: var(--colorDrawer);
  border-radius: 7px;
  min-width: 17.5em;
}

.parameter-list:not(:last-child) {
  margin-right: 1em;
}

.parameter-list.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.parameter-display {
  justify-self: stretch;
  flex-grow: 1;
  list-style-type: none;
  padding: 0;
  overflow-y: auto;
  margin: 0;
}

.parameter-name {
  display: flex;
  align-items: center;
}

.parameter-actions {
  display: flex;
  flex-direction: row;
}

.parameter-actions h4 {
  letter-spacing: 0;
  font-size: 1.2em;
  cursor: pointer;
  color: var(--color2);
}

.parameter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorBG);
  border-radius: 5px;
  margin: 0.5em 0;
  margin-top: 0;
  transition: opacity 0.25s ease;
  border: 2px solid lightgray;
}

.parameter-list .parameter .parameter-actions {
  padding: 0 0.5em;
}

.parameter h4 {
  margin: 0;
}

.parameter-title {
  font-weight: bolder;
  margin: 0;
  display: flex;
  align-items: center;
}

.dependency-warning {
  color: var(--color2);
  padding: 0 0.2em;
  cursor: help;
  margin: 0 0.25em;
}

.parameter-add {
  margin: 0;
  font-weight: bolder;
  font-size: 1em;
  color: var(--color2);
  cursor: pointer;
}

.parameter form {
  flex-grow: 1;
}

.parameter-input input,
.parameter-input {
  width: 90%;
  text-overflow: ellipsis;
}

.parameter-input {
  padding: 0.5em !important;
}

.parameter-add-input {
  border-color: var(--color3);
}

.parameter-input input:invalid {
  border-color: red;
}

.parameter-input input,
.parameter-input {
  border: 2px solid rgba(0, 0, 0, 0) !important;
  background-color: var(--colorBG) !important;
  margin: 0 !important;
  font-family: "Didact Gothic" !important;
  color: var(--colorFont);
  border-radius: 5px;
  text-align: start;
  resize: none;
}

.parameter-input input:read-only {
  background-color: white;
  border: none;
  font-size: 13px !important;
}
.parameter-input input:not(:read-only) {
  background-color: var(--colorDrawer);
  border: none;
  font-size: 13px !important;
}

.parameter-input input .parameter-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.editing-parameter {
  border-color: var(--color3);
}

.param-edit-active {
  color: var(--color3) !important;
}

.parameter-autocomplete-menu {
  width: 18.5em;
  word-break: break-all;
}
.parameter-list {
}
.parameter-list .parameter-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.parameter-list .parameter-list-title {
  margin: 0 !important;
  color: var(--color2);
  font-weight: bold;
}

.parameter-list .filter-input {
  background-color: white !important;
  border: none !important;
  padding: 0.5em;
}
.parameter-list .parameter-list-body {
  gap: 0.25em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
}

.parameter.editing {
  border-color: var(--color3);
}

.parameter-list .parameter,
.parameter-list .parameter input {
  transition: all 250ms ease-in;
  border-radius: 6px;
}
.parameter-list .parameter:hover {
  background-color: var(--colorDrawer);
}
.parameter-list .parameter:hover input {
  background-color: var(--colorDrawer) !important;
}

.parameter-list .parameter-actions {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.parameter-list .parameter-list-features {
  padding: 0 0.5em;
}
.param-feature-icon {
  color: var(--color2);
}
