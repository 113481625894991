.sliding-drawer {
  background-color: var(--colorDrawer);
  transition: background-color 0.5s ease-in, transform 0.2s ease-out,
    border-color 0.2s;
  border-color: var(--color3);
  position: fixed;
  text-align: center;
  display: flex;
  flex-direction: column;

  left: 5em;
  width: 15em;
  height: 100%;
  -webkit-box-shadow: inset -2px 0px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: inset -2px 0px 3px 0px rgba(0, 0, 0, 0.05);
  z-index: 20;
}

.sidenav-wrapper:hover .sliding-drawer {
  border-right: 2px solid var(--color3);
}

.sidenav-wrapper:hover .drawer-toggle-button {
  visibility: visible;
  border-color: var(--color3);
}

.panel {
  transition: background-color 0.5s ease-out;
  margin-bottom: 1em;
  margin: 0.5em;
  padding: 0.5em;
  margin-bottom: 0;
}

.option-panel {
  margin-bottom: 1.5em;
}

.team-panel {
  background-color: hsla(0, 0%, 82.7%, 0.35);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: box-shadow 0.5s;
}

.team-panel:hover {
  box-shadow: 0px 2px 2px 1px #c3c3c3;
}

.options-list {
  margin: 2em 0;
  margin-bottom: 10em;
  overflow: auto;
}

.drawer-label {
  font-size: 13px;
  text-align: start;
  margin: 0;
  color: var(--color1);
  font-weight: normal;
}

.drawer-title {
  text-align: start;
  margin: 0;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-title .org-edit-link {
  display: none;
  opacity: 0;
  transition: all 250ms ease-in-out;
}
.org-panel:hover .org-edit-link {
  display: block;
  opacity: 1;
}

.drawer-title .org-edit-button {
  background-color: hsla(0, 0%, 82.7%, 0.35);
  color: var(--color2);
}

.drawer-team {
  text-align: start;
  margin: 0;
  font-size: 1em;
  color: var(--color2);
  font-weight: bold;
}

.drawer-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  margin: 0;
  padding: 0.5em;
  transition: background-color 0.5s ease-out;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;
  color: black;
}

.drawer-option > h4 {
  margin: 0;
}

.drawer-option.action-button-color-3 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.drawer-option.action-button-color-3:hover {
  background-color: var(--color3);
  color: var(--color2);
}

.drawer-option.action-button-color-3 i {
  color: var(--colorDrawer);
}
.drawer-option.action-button-color-3 i:hover {
  color: var(--color1);
  cursor: help;
}
.drawer-option.action-button-color-3.selected i {
  color: var(--color2);
}

.drawer-option:hover {
  background-color: hsla(0, 0%, 82.7%, 0.35);
}

.drawer-option.selected h4 {
  color: var(--color2) !important;
  font-weight: bold;
}

.option-label {
  padding: 0.5em;
}

.return-panel {
  background-color: hsla(0, 0%, 82.7%, 0.35);
  border-radius: 5px;
}

.drawer-return {
  margin: 0;
  margin-top: 0.5em;
  font-size: 1em;
  text-align: start;
}
.drawer-return a,
.drawer-return i {
  color: black;
  transition: color 0.2s;
}

.drawer-return i {
  margin-right: 0.5em;
  font-size: 1em;
}

.drawer-return:hover a,
.drawer-return:hover i {
  color: var(--color3) !important;
}

.drawer-toggle-button {
  border: 2px solid var(--color3) !important;
  position: absolute !important;
  top: 2em;
  left: 16.75em;
}

.drawer-toggle-button i {
  padding: 4px 0 !important;
  padding-right: 6px !important;
}

.drawer-toggle-button:hover {
  background-color: var(--color3) !important;
}
.drawer-toggle-button:hover i {
  color: white;
}

.drawer-popup {
  width: 25em !important;
}

.sliding-drawer .team img {
  height: 3.2em;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.sliding-drawer .team {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  border-radius: 7px;
  padding: 0;
  padding-left: 0.25em;
  transition: background-color 1s;
  cursor: pointer;
}

.sliding-drawer .team:hover {
  background-color: rgba(211, 211, 211, 0.35);
}

.sliding-drawer .team .team-info {
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sliding-drawer .team .team-info h3,
.sliding-drawer .team .team-info h4 {
  font-size: 1em;
  text-align: start;
  margin: 0;
}

.sliding-drawer .team .team-info .team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8em;
}

.sliding-drawer .team .team-info h4 {
  background-color: rgba(211, 211, 211, 0.35);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5em;
  border-radius: 7px;
  color: var(--color2);
}

.sliding-drawer .team .team-info .team-select {
  width: 8em;
  margin: 0;
  padding: 0;
  font-family: "Didact Gothic";
  border: none;
  border-radius: 7px;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.sliding-drawer .items {
  min-width: 12.8em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  white-space: nowrap;
  margin: 0.75em;
}

.sliding-drawer .items .drawer-item {
  margin-bottom: 0.5em;
  padding-left: 0.25em;
}

.sliding-drawer.closed {
  transform: translate(-91%, 0px);
}

.sliding-drawer.open {
  transform: translate(0%, 0px);
}

.sliding-drawer:not(.open) .drawer-toggle-button {
  transform: rotate(180deg);
}

.view .small {
  margin-left: 17em !important;
}

.drawer-toggle-button {
  visibility: hidden;
}

.drawer-toggle-button:hover {
  background-color: var(--color3);
}

input:focus {
  outline: none;
}

.drawer-items-wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80%;
}

.drawer-items-wrapper div {
  width: 100%;
}

.drawer-items-second-level .drawer-second-level-return a {
  text-decoration: none;
  font-weight: lighter;
  color: var(--colorFont);
}
.drawer-items-second-level .drawer-second-level-return {
  border-bottom: 2px solid darkgray;
  padding: 0.5em 0;
  padding-bottom: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.drawer-items-second-level .drawer-second-level-return h4 {
  margin: 0;
}

.drawer-items-second-level .drawer-second-level-title {
  text-align: start;
  font-weight: bold;
  margin: 0;
  padding: 0.5em 0;
  padding-top: 1em;
  padding-left: 0.25em;
}

.drawer-items-second-level .drawer-second-level-return a i {
  transform: rotate(90deg);
  margin-right: 1em;
}

.first-level-enter {
  opacity: 0;
  transform: translateX(-210px);
}
.first-level-enter-active {
  opacity: 1;
  transition: opacity 200ms, transform 250ms linear;
  transform: translateX(0%);
}
.first-level-exit {
  opacity: 1;
}
.first-level-exit-active {
  opacity: 0;
  transform: translateX(-210px);
  transition: opacity 200ms, transform 250ms linear;
}

.second-level-enter {
  opacity: 0;
}
.second-level-enter-active {
  opacity: 1;
  transition: opacity 200ms, transform 250ms linear;
  transform: translateX(-200px);
}
.second-level-exit {
  opacity: 1;
  transform: translateX(-200px);
}
.second-level-exit-active {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 200ms, transform 250ms linear;
}

.third-level-enter {
  opacity: 0;
}
.third-level-enter-active {
  opacity: 1;
  transition: opacity 200ms, transform 250ms linear;
  transform: translateX(-170px);
}
.third-level-exit {
  opacity: 1;
  transform: translateX(-170px);
}
.third-level-exit-active {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 200ms, transform 250ms linear;
}

.team-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.team-placeholder {
  margin: 0.5em;
  border-radius: 5px;
}

.placeholder-label {
  padding: 0 0.5em;
}

.drawer-placeholder {
  margin: 0.25em 0 !important;
}

.highlight-selected {
  background-color: var(--color2);
}

.highlight-selected h4 {
  font-weight: bolder !important;
}

.highlight-selected h4,
.highlight-selected i {
  color: white;
}

.dev-tag {
  background-color: rgb(255 237 73) !important;
  margin-right: 0.5em;
  color: black !important;
}
