.campaign-taxonomy-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.campaign-taxonomy-selection .selected {
  color: var(--color2);
  font-weight: bolder;
}

.taxonomy-settings {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
}

.taxonomy-settings.dependency-wrap {
  flex-direction: row-reverse;
}

.taxonomy-settings.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.taxonomy-settings > div:last-child {
  min-height: 8.5em;

  border-radius: 7px;
  padding: 1em;
  flex-grow: 1;
  overflow-y: auto;
}
.taxonomy-settings .taxonomy-current {
  background-color: var(--colorDrawer);
  border-radius: 7px;
  margin: 0;
  margin-right: 1em;
  padding: 1em;
  width: 30%;
}

.taxonomy-settings .current {
  padding: 0 !important;
  margin: 2em 0;
}

.taxonomy-list:not(#selected-list) .set-tax {
  color: var(--color1);
  border-color: var(--color1);
  user-select: none;
  pointer-events: none;
  padding: 0;
}

.taxonomy-settings > div:first-child {
  min-height: 15em;
  border-radius: 7px;
  flex-grow: 1;
  overflow-y: auto;
}

.taxonomy-settings .taxonomy-setlist {
  width: 30% !important;
  max-width: 450px;
}
.taxonomy-settings .taxonomy-main {
  width: 68% !important;
  height: 100%;
}

.taxonomy-settings h4 {
  font-weight: bolder;
  margin: 0.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.taxonomy-settings .current h4 button {
  margin-left: 1em;
}

.taxonomy-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0.2em 0;
  margin: 0;
  padding-left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.taxonomy-list h4,
.taxonomy-list li {
  font-size: 0.75em;
  font-weight: bolder;
  padding: 0.25em 0.5em;
  margin: 0.25em;
}

.taxonomy-list.create {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 5em;
}

.create-tax {
  background-color: var(--colorBG);
  border: 2px solid lightgray;
  border-radius: 7px;
  padding: 0.5em;
  margin: 0.5em 0;
}

.create-tax.dragging {
  border: 2px dashed var(--color3);
}
.create-tax.empty {
  border: 2px dashed var(--color3);
}

.create-tax button {
  font-size: 0.7em !important;
}

.taxonomy-type-lists {
  display: flex;
  flex-direction: column;
}

.taxonomy-type-lists div:not(.rs-divider) {
  background-color: var(--colorBG);
  border-radius: 7px;
}

.taxonomy-type-lists > div:last-child {
  margin-right: 0;
}

.taxonomy-type-lists div h5 {
  margin: 0;
}

.taxonomy-types .rs-divider {
  height: initial;
}

.taxonomy-set {
  width: 68%;
}

.taxonomy-current-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-taxonomy-title {
  margin: 0;
  align-self: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.current-taxonomy-title .rs-tag {
  font-size: 13px;
  font-weight: bold;
  background-color: var(--color2) !important;
  color: white;
}

.taxonomy-current-edit .rs-divider {
  width: 100%;
}

.current-taxonomy-container {
  background-color: var(--colorDrawer);
  border-radius: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.current-taxonomy-container > div {
  background-color: var(--colorDrawer);
  border-radius: 0 0 7px 7px;
}

.current-taxonomy-container > .action-button {
  margin: 1em 0;
}

.current-taxonomy-container > ul {
  padding: 0.5em;
}

.current-taxonomy-container > div > ul {
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 0.5em;
}

.current-taxonomy-container > div > h4 {
  justify-content: center;
}

.taxonomy-list li {
  font-size: 13px;
}

.new-taxonomy-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--colorDrawer);
  border-radius: 7px;
  padding: 0.5em;
  margin: 1em 0;
}

.new-taxonomy-preview > ul {
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 0.5em;
  margin: 0;
  width: 100%;
}

.new-taxonomy-preview > ul > h5 {
  margin: 0;
}

.taxonomy-types {
  display: flex;
}

.new-taxonomy-title {
  margin: 0;
}
.new-taxonomy-title .rs-tag {
  margin-left: 3em;
  background-color: var(--color2) !important;
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.drag-tip {
  width: 100%;
  pointer-events: none;
  position: relative;

  margin: 0 auto;
}

.drag-tip > div {
  position: absolute;
  width: 100%;
}

.drag-tip h4 {
  width: 100%;
  text-align: center;
  color: darkgray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.drag-tip h4 i {
  margin-right: 0.5em;
}

.add-param-taxonomy {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-param-taxonomy i {
  margin-right: 0.5em;
}

.add-param-taxonomy a {
  color: white;
  font-size: inherit;
}

.param-not-found {
  color: red !important;
}
.current-taxonomy-container .taxonomy-list li,
.new-taxonomy-preview .taxonomy-list li {
  padding: 0 !important;
}
