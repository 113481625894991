.breadcrumb {
  display: flex;
  flex-direction: row;
}
.breadcrumb .breadcrumb-page {
  margin: 0;
  margin-right: 0.2em;
  text-decoration: none;
  color: var(--colorFont);
}
.breadcrumb .breadcrumb-page h3 {
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  color: #756e6e;
}

.breadcrumb .breadcrumb-page h3:hover {
  color: var(--color2);
}
