.team-selector {
  min-height: 52px;
  transition: height 0.5s;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: 5em;
  width: 13.9rem;
  margin: 0 0.5em;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 1px #c3c3c3;
  margin-right: 1em;
  z-index: 20;
  max-height: 30em;
}

.team-selector.visible {
  visibility: visible !important;
}

.team-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  cursor: pointer;
  margin: 0 0.5em;
  padding: 0 0.5em;
  transition: all 250ms;
}

.team-option:hover {
  background-color: rgba(211, 211, 211, 0.35);
}

.team-option.selected i {
  color: var(--color2);
}

.team-option.selected h5 {
  color: var(--color2);
  font-weight: bold;
}

.team-selector h4 {
}

.team-option h5 {
  margin: 0.5em 0;
  font-weight: normal;
}

.manage-team-padding {
  padding: 0 0.5em !important;
}

.team-selector-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.orgs > div {
  max-height: 30%;
  overflow: auto;
}

.team-list {
  max-height: 20em;
  overflow: auto;
  margin: 0.5em 0;
}

.info-icon:hover {
  cursor: help;
  color: var(--color3);
}

.org-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em;
  border-radius: 7px;
  cursor: pointer;
}

.org-select a {
  border: none !important;
  background-color: rgba(211, 211, 211, 0.35);
}

.org-select .rs-picker-toggle-value {
  color: var(--color2) !important;
  font-weight: bold;
}

.org-select .rs-btn.rs-btn-default.rs-picker-toggle,
.org-select .rs-input,
.org-select .rs-input-group,
.org-select .rs-picker-tag {
  border: none !important;
}

.selector-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
}

.multiple-select-team {
  padding: 0 1em;
  margin-top: 0.5em;
}

.current-team-selector {
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.5s ease-out;
  background-color: #e9e9e9;
}
