.dependency-parameter-list {
  list-style-type: none;
  padding-left: 0;
}

.dependency-parameter-list li {
  width: 90%;
  font-size: 0.75em;
  font-weight: bolder;
  padding: 0.25em 0.5em;
  margin: 0.25em 0.25em;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  white-space: normal;
  text-align: start;
}

.dependency-parameter-list .has-dependency {
  transform: translateX(10%);
}
.dependency-parameter-list .has-dependency i {
  pointer-events: none;
  opacity: 0.5;
}

.parameter-search {
  display: flex;
  align-items: center;
}

.parameter-search input {
  width: 100%;
  margin-right: 0;
}

.parameter-search i {
  position: relative;
  left: 0.6em;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  margin-right: -1em;
}

.dependency-catalog {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dependency-catalog > div:last-child {
  display: flex;
  overflow-y: hidden;
}

.dependency-catalog-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dependency-catalog-actions .select-dropdown {
  flex: 1;
  margin: 0 !important;
}

.dependency-catalog-actions h5 {
  flex: 1;
  margin-left: 1em;
  margin: 0.5em;
  pointer-events: none;

  min-height: 2.6em;
  text-align: center;
}

.dependency-catalog-list {
  list-style-type: none;
  padding-left: 0;
  flex-grow: 1;
  overflow-y: auto;
  width: 50%;
}

.dependency-catalog-list > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  margin: 0.5em 0;
  border-radius: 5px;
  background-color: var(--colorDrawer);
}

.dependency-catalog-list li > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15em;
}
.parameter-dependencies-list {
  list-style-type: none;
  margin-left: 2em;
  min-height: 2.35em;
  padding-left: 0;
  width: 100%;
  color: var(--color2);
  background-color: var(--colorBG);
  border: 2px solid var(--color2);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.parameter-dependencies-list h5 {
  margin: 0.25em;
  padding: 0.25em 0.25em;
}

.dependency-selection-param-list {
  list-style-type: none;
  padding-left: 0;
  overflow: auto;
  color: var(--color2);
  background-color: var(--colorBG);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dependency-selection-param-list h5 {
  margin: 0;
  padding: 0.1em 0.5em;
}

.dependency-main {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
}

.dependency-list-catalog {
  margin: 0.5em;
  width: 50%;
  overflow-y: auto;
}

.parameter-dependencies-list li h5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  white-space: normal;
}

.dependency-delete:hover,
.dependency-warning:hover {
  color: var(--color3) !important;
}

.parameter-dependencies-list li:hover h4,
.parameter-dependencies-list li:hover i {
  color: var(--colorBG);
}

.catalogued-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.1em !important;
  margin: 0.3em;
  height: 1.7em;
}

.catalogued-item .dependency-length {
  background-color: var(--colorBG);
  color: var(--colorFont);
  margin: 0 0.2em;

  border-radius: 3px;
}

.border-color-3 {
  border-color: var(--color3) !important;
  color: var(--color3) !important;
}

.dependency-warning {
  font-size: 1.5em;
  padding: 0;
  margin-right: 0.5em;
}

.dependency-delete {
  font-size: 1.2em;
  margin: 0 !important;
  margin-left: 1em !important;
  color: var(--color2);
  transition: color 0.2s;
}

.parameter-dependencies-list.selected {
  border-color: var(--color3);
}

.catalog-amount {
  position: relative;
  display: inline;
  margin: 0;
  color: var(--color2);
}

#dependency-list div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#utm-dependency-visualizer .parameter-search,
#campaign-dependency-visualizer .parameter-search {
  margin-bottom: 1em;
}

#utm-dependency-visualizer .parameter-search input,
#campaign-dependency-visualizer .parameter-search input {
  margin-left: 0;
}

.preview-margin {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  border-color: 5px solid var(--color3);
  color: var(--color3);
}

.dependency-father-selected {
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#parameter-management .taxonomy-setlist {
  background-color: var(--colorDrawer);
}
