.debug-icon {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 100;
  background-color: var(--color3);
  color: white;
  border: 2px solid var(--color3);
  &:hover {
    background-color: white;

    i {
      color: var(--color3);
    }
  }

  i {
    font-size: 20px !important;
    height: 20px;
    padding: 2px 0 !important;
  }
  h3,
  h5 {
    font-family: "Didact Gothic" !important;
  }
}

.debug-menu {
  .rs-drawer-content {
    background-color: var(--colorDrawer);
  }
  h4 {
    margin: 0;
  }

  .debug-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--color2);
    border-radius: 6px;
    padding: 0.5em;
  }

  .debug-core-vitals-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;

    .debug-core-vitals-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--color2);
      border-radius: 6px;
      padding: 0.5em;
      width: 30%;
      background-color: white;

      .rs-tag {
        margin-top: 0.5em;
      }
      h4:last-child {
        font-weight: bold;
        text-align: center;
      }

      &.good {
        .rs-tag {
          background-color: green;
          h4 {
            color: white;
          }
        }
      }
      &.needs-improvement {
        .rs-tag {
          background-color: orange;
          color: white;
        }
      }
      &.poor {
        .rs-tag {
          background-color: red;
          color: white;
        }
      }

      h3 {
        margin: 0;
      }
    }
  }
  .error-log-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
  }

  .debug-error-list {
    border: 2px solid lightgray;
    background-color: white;
    padding: 0.5em;
    border-radius: 6px;

    .rs-timeline {
      margin-top: 0;

      .debug-error-item {
      }
    }
  }
}

@reset-import: false;@font-size-base: 13px;@font-family-base: Didact Gothic;@input-bg: var(--colorDrawer);@calendar-table-cell-content-hover-bg: var(--colorDrawer);@calendar-table-cell-content-selected-color: var(--color3);@calendar-table-cell-content-today-border-color: var(--color3);@calendar-right-btn-ok-bg: var(--color2);@calendar-option-color: var(--color2);@picker-menu-item-hover-bg: var(--colorDrawer);@slider-bar-default-bg: inherit;@slider-bar-hover-bg: inherit;@slider-progress-bar-bg: var(--color3);@steps-bg: white;@steps-icon-process-bg: var(--color2);@steps-icon-process-color: white;@steps-content-process-color: var(--color3);@steps-icon-finish-color: var(--color3);