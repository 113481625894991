@import (reference) "./../../App.less";

.header-panel {
  margin: 0em 0em;
  border-radius: 0;

  .rs-panel-body {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    max-width: 0px;
    margin: 0 auto;

    h1 {
      width: 100%;
      color: white;
      text-align: center;
      margin: 0.5em 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .header-panel {
    .rs-panel-body {
      h1 {
        text-align: start;
      }
    }
  }
}

@reset-import: false;@font-size-base: 13px;@font-family-base: Didact Gothic;@input-bg: var(--colorDrawer);@calendar-table-cell-content-hover-bg: var(--colorDrawer);@calendar-table-cell-content-selected-color: var(--color3);@calendar-table-cell-content-today-border-color: var(--color3);@calendar-right-btn-ok-bg: var(--color2);@calendar-option-color: var(--color2);@picker-menu-item-hover-bg: var(--colorDrawer);@slider-bar-default-bg: inherit;@slider-bar-hover-bg: inherit;@slider-progress-bar-bg: var(--color3);@steps-bg: white;@steps-icon-process-bg: var(--color2);@steps-icon-process-color: white;@steps-content-process-color: var(--color3);@steps-icon-finish-color: var(--color3);