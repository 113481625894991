.order-modal {
  font-family: "Didact Gothic";
}

.order-modal .rs-divider {
  margin: 0.5em 0;
}

.order-modal-body {
  margin: 0 !important;
  padding-bottom: 1em !important;
}

.order-modal-header h2 {
  margin: 0;
}

.order-info {
  display: flex;
}

.order-info .data {
  width: 50%;
}
.order-info .data h5 {
  font-size: 1em;
  margin-left: 0.5em;
}
.order-info .data input,
.order-info .data .rs-picker-select {
  width: 80%;
}

.order-info .data .final-data {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-info .data .final-data h4 {
  margin: 0.7em 0;
  margin-left: 0.5em;
}

.order-postcode-div {
  display: flex;
  flex-direction: row;
}

.order-postcode-div .order-postcode {
  width: 60% !important;
}
.order-postcode-div .order-postcode-area {
  width: 40% !important;
}

.order-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em;
  background: var(--colorDrawer);
  border-radius: 7px;
}

.order-item-info {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-item-info h4 {
  margin: 0;
  text-align: center;
  font-weight: normal;
}

.order-item-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.order-item-info img {
  width: 3em;
  height: 3em;
  margin-right: 0.5em;
}

.order-item-logo h3 {
  margin: 0;
}

.order-item-amount {
  display: flex;
  align-items: end;
}
.order-item-amount h3 {
  margin: 0;
  text-align: end;
}
.order-item-amount h4 {
  margin: 0;
}

.order-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em;
}

.order-total h3 {
  margin: 0;
}

.order-total .drawer-label {
  font-size: 18px;
}

.order-total h3:last-child {
  margin-left: 3.3em;
}

.start-subscription {
  width: 100%;
}

.start-subscription:disabled {
  background-color: lightgray;
  border: none;
  cursor: default;
}

.start-subscription .rs-tag {
  margin-left: 0.5em;
}

.order-data-editing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.order-data-editing h3 {
  margin: 0;
}
.order-data-editing button {
  margin-left: 1em;
}

.order-payment-selection {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 7px;
}
.order-payment-selection .rs-picker-select {
  width: 100%;
  margin: 0;
}

.rs-picker-menu.fade.in.placement-bottom-start.rs-picker-select-menu {
  z-index: 2002 !important;
}

.order-payment-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-payment-item div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-payment-item h4 {
  margin: 0 1em;
  font-size: 13px;
}

.data-editing-actions {
  margin: 1em 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.data-editing-actions button {
  width: 20%;
}

.order-add-new-payment {
  margin: 0.5em;
}

.order-add-new-payment .rs-divider {
  margin: 0.5em 0;
}

.order-new-method-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.order-new-method-options button {
  width: 50%;
}

.order-info .rs-picker-disabled {
  opacity: 1;
}

.order-info .rs-picker-toggle {
  border: none !important;
}
.order-info .rs-picker-disabled .rs-picker-toggle {
  cursor: initial !important;
  background-color: white !important;
  border-color: white !important;
}

.order-info .rs-picker-disabled .rs-picker-toggle-caret {
  display: none;
}

.order-info .rs-input-group {
  margin: 0.5em 0 !important;
}

.order-info .rs-input-group.editing {
}

.plan-change-warning {
  background-color: var(--color2);
  border-radius: 6px;
  padding: 0.5em;
  margin: 1em 0;
}

.plan-change-warning h4 {
  color: white;
  font-weight: bold;
  margin: 0;
}

.order-warning-message {
  background-color: var(--color2);
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
}

.order-warning-message i {
  margin-right: 0.5em;
  margin-top: 0.25em;
  color: white;
}

.order-warning-message h4 {
  color: white;
  font-weight: bold;
  margin: 0;
}
