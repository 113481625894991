.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
  padding: 0 1.5em;
  padding-right: 1em;
  background-color: white;
  color: black;
  user-select: none;
}

.navbar .title {
  font-size: 24px;
  margin: 0.2em;
}

.navbar .header,
.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar .header > a {
  margin-left: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: black;
}

.navbar .header img {
  width: 2em;
  margin: 0 1em;
  margin-right: 0.15em;
}

.user-menu-link {
  width: 2em;
  height: 2em;
  text-align: center;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 0.3em 0;
  margin: 0;
  background-color: var(--color2);
  cursor: pointer;
}

.user-menu-profile-img {
  font-size: 18px;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin: 0;
  border: 2.5px solid var(--color3);
  cursor: pointer;
  transition: border-color 0.2s;
}

.user-menu-profile-img:hover {
  border-color: var(--color2);
}

.navbar a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
}

.settings-link {
  margin-right: 0.5em;
  letter-spacing: 0;
  transition: color 0.2s;
}

.settings-link:hover {
  color: var(--color2);
}

.nav-flag {
  width: 2em;
  border-radius: 3px;
}

.header .rs-dropdown-item img {
  width: 1.5em;
  margin: 0;
  margin-right: 1em;
}

.header .rs-dropdown-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header .rs-dropdown-item.active .rs-dropdown-item-content {
  font-weight: bold;
  color: var(--color2);
}

.header .drawer-label {
  padding: 0.5em 1em;
}

#pf-announcements {
  margin-right: 1em;
  border-radius: 50px;
  padding: 0.5em 0.75em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#pf-announcements i.unread {
  color: var(--color3);
}

#pf-announcements h5 {
  margin: 0;
  margin-left: 0.5em;
}
