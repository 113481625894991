.parameter-lists-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 100%;
  overflow: auto;
}

.parameter-type {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0.5em;
}

.parameter-type:first-child {
  margin-left: 0;
}
.parameter-type:last-child {
  margin-right: 0;
}

.parameter-type > h4 {
  margin: 0;
  font-weight: bold;
  color: var(--color2);
  background-color: var(--colorDrawer);
  text-align: center;
  border-radius: 7px;
  padding: 0.5em;
}

.parameter-lists {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-y: auto;
}
