.subtext {
  color: gray;
  margin: 0.5em 0;
  margin-bottom: 0.5em;
  font-size: 1em;
}

.concatonate-option h3,
.concatonated-separator h3 {
  font-size: 1em;
}

.concatonate-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.concatonated-separator {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
}

.concatonated-separator input {
  font-size: 1em;
  text-align: center !important;
  width: 2.5em;
  height: 2.5em;
  border-radius: 6px;
  border: 2px solid lightgray;
  background-color: var(--colorDrawer);
}

.parameter-list-wrapper h4 {
  margin: 0;
  margin-bottom: 0.5em;
  color: var(--color2);
  font-weight: bolder;
}
.no-padding {
  padding: 0;
}

.param-concat-manage {
  width: 95%;
  margin: 0.25em 0;
  border: 2px solid lightgray !important;
  background-color: white;
}

.param-concat-manage .rs-input-group-addon:first-child div,
.param-concat-manage .rs-input-group-addon:first-child div button {
  background: var(--colorDrawer);
}

.param-concat-manage input {
  padding-left: 0 !important;
}
.rs-input-group button {
  color: var(--color2);
}

.parameter-list-wrapper .rs-checkbox-wrapper {
  background: var(--colorDrawer);
  border-radius: 3px;
}

.param-icon {
  font-size: 1.5em !important;
  max-width: initial;
  letter-spacing: initial;
}

.nested-param {
  margin-left: 1.5em !important;
}

.param.input {
  font-size: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  padding-left: 1em !important;
  margin-right: 1em !important;
  color: var(--colorFont);
  background-color: white;
  border-radius: 5px;
  text-align: start;
}
.param.input:read-only {
  color: var(--color2);
  font-weight: bolder;
  background-color: var(--colorDrawer);
}

.param.input.disabled {
  color: gray !important;
}

.param.input.custom {
  padding: 0 !important;
}

#parameter-management .setting-list {
  padding: 0 0.5em;
}

.parameter-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.parameter-list-wrapper > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.parameter-list-wrapper .icon {
  font-size: 1.25em;
}

.parameter-actions {
  display: flex;
  align-items: center;
}

.parameter-actions h4 {
  margin: 0;
  margin-top: 0.1em;
}

.parameter-actions input {
  width: initial;
  zoom: 1.6;
}

.concat-param {
  margin-bottom: 0.5em !important;
}

#parameter-management .profile-submit {
  font-size: 13.3333px;
}

.primary-check {
  width: initial !important;
  zoom: 1.6;
  margin-right: 0.1em !important;
}

.editing-active-border {
  border: 2px solid var(--color3) !important;
  margin: 0.5em 0 !important;
}

.parameter-list-wrapper
  .rs-input-group:not(.rs-input-group-inside):not(.param-concat-manage):not(
    .editing-active-border
  ) {
  border: 2px solid lightgray;
  margin: 0.25em 0 !important;
  background-color: var(--colorBG);
}

.parameter-list-wrapper input {
  color: var(--color2) !important;
  font-weight: bold;
  font-size: 1em !important;
  font-family: "Didact Gothic" !important;
  background-color: var(--colorBG);
  border-radius: 5px;
  text-align: start;
}
.parameter-list-wrapper .rs-input-group-addon {
  padding: 0 !important;
}

.parameter-list-wrapper .rs-btn.rs-btn-default {
  display: flex;
  background-color: white;
  padding: 0.5em;
}

.parameter-list-wrapper .rs-btn-group {
  background-color: white;
}

.rs-input.parameter-list-param:disabled {
  opacity: 0.5;
  background-color: white;
  color: gray !important;
}

.editing-active {
  color: var(--color3) !important;
}

.concatonate-option.margin-top {
  margin-top: 1em;
}

.param-concat-manage .rs-input-group-addon:first-child .rs-btn-group,
.param-concat-manage .rs-input-group-addon:first-child .rs-btn-group button {
  background-color: var(--colorBG) !important;
}

.concat-disabled label {
  opacity: 0.5;
}

.concat-disabled {
  display: flex;
}

.concat-toggles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.concat-toggles > div {
  margin: 0.5em 0;
  justify-content: space-between;
}

.concat-toggles .rs-btn-toggle {
  margin: 0;
  margin-left: 0.5em;
}

.parameter-action-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0.5em;
  background-color: var(--colorDrawer);
  border-radius: 6px;
  margin: 1em 0;
}

.parameter-action-group:first-child {
  margin-top: 0;
}

.parameter-action-group:last-child {
  margin-bottom: 0;
}

.parameter-action-group .rs-divider {
  width: 100%;
}

.parameter-list-wrapper {
  width: 100%;
}

.dependency-tooltip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.dependency-tooltip-list .rs-tag {
  margin: 0.25em 0 !important;
}

.param-action-list .rs-icon {
  font-size: 20px;
}

.rs-tooltip {
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
}

.rs-tooltip-inner {
  background-color: white !important;
  color: black !important;
  font-family: "Didact Gothic" !important;
}

.rs-tooltip-arrow {
  color: white !important;
  border-top-color: white !important;
}

.parameter-list-wrapper .param-action-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.parameter-list-wrapper .param-action-list .rs-btn {
  float: initial;
}

.parameter-list-wrapper .rs-checkbox-checker {
  min-height: 37px;
}

.parameter-list-param {
  padding-left: 0.25em !important;
}

.parameter-list-wrapper .rs-input-group {
  padding-left: 0.25em;
}

.parameter-list-wrapper .info-button.added {
  color: #6aa84f;
}
.parameter-list-wrapper .info-button.edited {
  color: #f1c232;
}
.parameter-list-wrapper .info-button.deleted {
  color: #cc0000;
}

.action-info {
  border-radius: 50% !important;
  background-color: var(--colorDrawer) !important;
}

.parameter-list-param.deleted {
  text-decoration: line-through;
  text-decoration-color: #cc0000;
  text-decoration-thickness: 2px;
  opacity: 0.5;
}
.parameter-list-param.inactive {
  opacity: 0.5;
}

.parameter-list-wrapper .param-concat-manage {
  width: 95%;
}

.parameter-list-wrapper .rs-input-group-addon {
  background-color: white !important;
}

.parameter-list-wrapper .group-editing {
  border-color: var(--color3) !important;
}
