.profile-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.profile-wrapper > * {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.profile-wrapper .profile-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-wrapper .profile-picture img {
  width: 12em;
  height: 12em;
  border: 2px solid black;
  border-radius: 50%;
  margin: 1em;
  margin-left: 2.5em;
  margin-top: 0em;
}
.profile-wrapper .profile-picture div:hover i {
  visibility: visible;
}

.profile-user-img {
  width: 2em;
  height: 2em;
  text-align: center;
  border: none;
  font-size: 6em;
  border: 1px solid black;
  border-radius: 50%;
  color: white;
  padding: 0.3em 0;
  margin: 0;
  margin-bottom: 1rem;
  background-color: var(--color2);
}

.profile-img-remove {
  position: relative;
  right: 1.5em;
  z-index: 10;
  float: right;
  visibility: hidden;
}

.profile-setting {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.profile-setting input,
.profile-setting select,
.profile-setting textarea {
  width: 100%;
  font-size: 1em;
  margin: 0;
}

.profile-submit:disabled,
.profile-submit select:disabled {
  background-color: lightgray;
  border: none;
  cursor: default;
}

.profile-setting h3 {
  margin: 0.2em 0;
}

.profile-submit {
  width: 10em !important;
  align-self: flex-end;
  margin: 0em 0;
  margin-top: 1em !important;
}

.setting-list {
  background-color: var(--colorDrawer);
  border-radius: 7px;
  padding: 0.5em;
  max-height: 20em;
  overflow-y: auto;
}

.setting-list .list-wrapper .rs-input-group i {
  margin-right: 0.25em;
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.list-wrapper h4,
.list-wrapper h5 {
  margin: 0.1em;
}

.team-button {
  margin: 0.5em 0;
  align-self: flex-end;
}
.list-wrapper
  .rs-input-group:not(:first-child):not(:last-child):not(.rs-input-group-inside):not(.param-concat-manage):not(.editing-active-border) {
  border: 2px solid lightgray !important;
  margin: 0.25em 0 !important;
}

.list-wrapper
  .rs-input-group:last-child:not(.rs-input-group-inside):not(.param-concat-manage):not(.editing-active-border) {
  border: 2px solid lightgray !important;
  margin: 0 !important;
  margin-top: 0.25em !important;
}

.list-wrapper
  .rs-input-group:first-child:not(.rs-input-group-inside):not(.param-concat-manage):not(.editing-active-border) {
  border: 2px solid lightgray !important;
  margin: 0 !important;
  margin-bottom: 0.25em !important;
}

.list-wrapper
  .rs-input-group:not(.rs-input-group-inside):not(.param-concat-manage):not(.editing-active-border)
  input {
  border-radius: 7px;
  padding: 0.5em !important;
  font-size: 1em !important;
  font-weight: bold;
  text-align: start;
}
.list-wrapper .rs-input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--colorBG) !important;
}

.list-wrapper .rs-input-group div {
  flex-grow: 1;
}

.list-wrapper .rs-input {
  background-color: var(--colorBG) !important;
}

.list-wrapper .rs-input-group-addon {
  padding: 0 !important;
  background-color: var(--colorBG) !important;
}

.list-wrapper .rs-input-group-addon .rs-btn-group {
  background-color: lightgray !important;
}

.list-wrapper:not(.payment-saved-methods) .rs-input-group-addon button {
  background-color: lightgray !important;
  font-weight: bold;
  font-size: 1em !important;
  padding: 0.5em;
}

.list-wrapper .rs-input-group-addon input {
  text-align: end;
}

.email-readonly {
  border: 2px solid lightgray !important;
  margin: 0.5em !important;
  padding: 0.5em !important;
  border-radius: 7px;
  font-size: 1em !important;
  font-weight: bold;
  color: #575757;
}
