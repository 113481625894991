.open {
  visibility: visible !important;
}

.user-menu-option.logout {
  cursor: pointer;
}

.user-menu .profile-img {
  font-size: 18px;
  width: 2em;
  height: 2em;
  border-radius: 50%;
}
.user-menu .profile-initial {
  width: 2em;
  height: 2em;
  text-align: center;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 0.35em 0;
  margin: 0;
  background-color: var(--color2);
}

.user-menu-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
}

.user-menu a {
  text-decoration: none;
}

.user-menu h4 {
  color: black;

  padding: 0.5em 0;
  padding-left: 0.25em;
  margin: 0;
  font-weight: lighter;
}

.user-menu-options {
}

.user-menu-option h4 {
  border-radius: 7px;
  transition: background-color 0.25s ease, color 0.25s ease;
}

.user-menu-option:hover h4 {
  background-color: rgba(211, 211, 211, 0.35);
}

.user-menu-info {
  width: 85%;
  margin-left: 0.5em;
}

.user-menu-info .text-placeholder:first-child .rs-placeholder-paragraph-rows p {
  margin-top: 0 !important;
}

.user-menu-info h4,
.user-menu-info h5 {
  font-weight: bolder;
  color: black;
  margin: 0;
  padding: 0 0.5em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 1.5;
}

.user-menu-info h4 {
  color: var(--color2);
}

.user-menu-info h5 {
  margin: 0;
}

.user-menu-agency-account {
  padding: 0.5em 0;
}

.user-menu-agency-account h4 {
  padding: 0;
  padding-left: 0.25em;
}

.user-menu hr {
  border: 1px solid whitesmoke;
  margin-left: 0;
  margin-right: 0;
}

.language-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.theme-switcher {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1em 0;
}

.theme-switcher img {
  width: 2em;
}

.theme-switcher .theme-preview {
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  cursor: pointer;
}
.theme-preview.selected {
  border: 2px solid var(--color3);
}

.klein {
  background-color: #002fa6;
  background: linear-gradient(
    -45deg,
    #15db95,
    #15db95 49%,
    #080f5b 49%,
    #080f5b 51%,
    #002fa6 51%
  );
}

.intense {
  background-color: #657a00;
  background: linear-gradient(
    -45deg,
    #ff4838,
    #ff4838 49%,
    #374200 49%,
    #374200 51%,
    #657a00 51%
  );
}

.lightning {
  background-color: #666666;
  background: linear-gradient(
    -45deg,
    #51d0de,
    #51d0de 49%,
    #1e1f23 49%,
    #1e1f23 51%,
    #666666 51%
  );
}

.apricot {
  background-color: #7d3780;
  background: linear-gradient(
    -45deg,
    #e9bd43,
    #e9bd43 49%,
    #4a2c40 49%,
    #4a2c40 51%,
    #7d3780 51%
  );
}

.cyberred {
  background-color: #395f6a;
  background: linear-gradient(
    -45deg,
    #ff1d58,
    #ff1d58 49%,
    #11314d 49%,
    #11314d 51%,
    #395f6a 51%
  );
}

.zen {
  background-color: #d5d2c4;
}

.country-select-dropdown {
  width: 100%;
}

.country-select-dropdown span {
  width: 100%;
}

.country-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  width: 100%;
  transition: background-color 0.25s ease, color 0.25s ease;
}

#user-menu .country-select:hover {
  background-color: rgba(211, 211, 211, 0.35);
}

.country-select img {
  width: 32px;
  height: 20px;
  margin-right: 0.25em;
  border-radius: 4px;
}

.country-select h4 {
  margin: 0;
}

.country-select-dropdown .rs-dropdown-menu {
  width: 100%;
  max-height: 25em;
  overflow: auto;
}

.country-select-dropdown .rs-dropdown-menu .rs-dropdown-item-content {
  padding: 0;
  margin: 0 0.75em;
}

.menu-legal {
  display: flex;
  justify-content: center;
  margin-top: 0.25em;
}

.menu-legal a {
  text-align: center;
  text-decoration: none;
  color: black;
  margin: 0.5em;
  font-size: 13px;
  padding: 0.25em;
  transition: background-color 0.25s ease, color 0.25s ease;
  border-radius: 5px;
}

.menu-legal a:hover {
  background-color: var(--colorDrawer);
}

.rs-avatar {
  background-color: var(--color2);
  margin: 0.25em 0;
  cursor: pointer;
}
